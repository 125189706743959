import {useState} from "react";

import EmailVerify from "./components/clientSurvey/EmailVerify";
import Feedback from "./components/clientSurvey/Feedback";
import Result from "./components/clientSurvey/Result";

import "./App.css";

function App() {
  const [pageNum, setPageNum] = useState(0);

  return (
    <div className="container">
      {/* <EmailVerify setPageNum={setPageNum} /> */}
      {pageNum === 0 ? <EmailVerify setPageNum={setPageNum} /> : <></>}
      {pageNum === 1 ? <Feedback/> : <></>}
      {pageNum === 2 ? <Result/> : <></>}
    </div>
  );
}

export default App;
