import React from "react";
import {useState} from "react";

function Feedback() {
  const [bouncyChecks, setBouncyChecks] = useState({
    price: {value: false, text: "Price/Budget"},
    time: {value: false, text: "Convenience of treatment times"},
    year: {value: false, text: "Time of year"},
    pain: {value: false, text: "pain"},
    other: {value: false, text: "No other areas apply for laser hair removal"},
    spouse: {value: false, text: "Other"},
    purchase: {value: false, text: "nothing is stopping me, i am purchasing additional areas today!"},
  });
  const [bouncyChecksError, setBouncyChecksError] = useState(false);

  return (
    <>
      <h2>How likely are you to recommend Milan Laser to a friend or colleague?</h2>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="Radios" id="exampleRadios1" value={0} checked />
        <label class="form-check-label" for="exampleRadios1">
          0
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="Radios" id="exampleRadios2" value={1} />
        <label class="form-check-label" for="exampleRadios2">
          1
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="Radios" id="exampleRadios2" value={2} />
        <label class="form-check-label" for="exampleRadios2">
          2
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="Radios" id="exampleRadios2" value={3} />
        <label class="form-check-label" for="exampleRadios2">
          3
        </label>
      </div>
      <p>buttons for ratings</p>
      <h2>What would stop you from getting other areas treated? Check all that apply</h2>
      <p>bunch of checklist stuff</p>
    </>
  );
}

export default Feedback;
