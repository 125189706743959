import React from "react";
import {useState} from "react";
import axios from "axios";
import querystring from "querystring";

function EmailVerify({setPageNum}) {
  const [emailInput, setEmailInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("no errors");
  const [loading, setLoading] = useState(false)

  const checkEmail = (e) => {
    //prevents from submitting the form so it doesn't refresh
    e.preventDefault();
    setLoading(true);
    // console.log("in checkEmail function");
    // console.log("current email we're chekcing is: ", emailInput);
    if (emailInput === "") {
      setErrorMessage("Email field cannot be empty");
      setLoading(false);
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput)) {
      setErrorMessage("Email address is invalid");
      setLoading(false);
    } else {
      const emailObj = {emailInput};
      // console.log("emailObj: ", emailObj);
      axios
        .post("https://cors-milanlaser.herokuapp.com/https://milan-laser.secure.force.com/clientcheckemail/services/apexrest/checkclientemail", querystring.stringify(emailObj))
        .then((res) => {
          console.log(res.data);
          if (res.data === "yes") {
            setErrorMessage("email is in salesforce!!! YAY!");
            setPageNum(1);
            // console.log("email is in salesforce!!! YAY!");
          } else {
            setErrorMessage("Email does not match in Salesforce. Please check email and try again.");
            // console.log("Email does not match in Salesforce. Please check email and try again.");
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        })
    }
  };

  return (
    <>
      <div>Email Verify component</div>
      <form>
        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            id="InputEmail"
            aria-describedby="emailHelp"
            placeholder="Client's Email"
            onChange={(e) => {
              setEmailInput(e.target.value);
              console.log(emailInput);
            }}
          />
          <div className="form-text">Please make sure you use the email that's in Salesforce.</div>
          <div>{errorMessage}</div>
        </div>
        {/* <button className="" onClick={checkEmail}> */}
        <button
          className=""
          onClick={(e) => {
            checkEmail(e);
          }}
        >
          Submit
        </button>
      </form>
      {loading && <h1>LOADING!!!</h1>}
    </>
  );
}

export default EmailVerify;
